import React, { useEffect,useState } from "react";
import Select from 'react-select';
import "./FormRegister.css"; // Import the CSS file for styling
 
import pent from './int3.jpg';

 
import Swal from 'sweetalert2';

import dlogo from './noimage.png';

import { api } from '../Api';
import axios from "axios";




const options = [
  // { value: '', label: '--Select Profession--' },
    { value: 'Accountant', label: 'Accountant' },
    { value: 'Actor', label: 'Actor' },
    { value: 'Agriculturalist', label: 'Agriculturalist' },
    { value: 'Air-Traffic-Controller', label: 'Air Traffic Controller' },
    { value: 'Architect', label: 'Architect' },
    { value: 'Artist', label: 'Artist' },
    { value: 'Astronaut', label: 'Astronaut' },
    { value: 'Biomedical-Engineer', label: 'Biomedical Engineer' },
    { value: 'Brewer', label: 'Brewer' },
    { value: 'Business-Person', label: 'Business Person' },
    { value: 'Carpenter', label: 'Carpenter' },
    { value: 'Chef', label: 'Chef' },
    { value: 'Chemist', label: 'Chemist' },
    { value: 'Civil-Engineer', label: 'Civil Engineer' },
    { value: 'Clinical-Psychologist', label: 'Clinical Psychologist' },
    { value: 'Content-Creator', label: 'Content Creator' },
    { value: 'Data-Scientist', label: 'Data Scientist' },
    { value: 'Dentist', label: 'Dentist' },
    { value: 'Electrician', label: 'Electrician' },
    { value: 'Economist', label: 'Economist' },
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Environmental-Scientist', label: 'Environmental Scientist' },
    { value: 'Event-Planner', label: 'Event Planner' },
    { value: 'Entrepreneur', label: 'Entrepreneur' },
    { value: 'Fashion-Designer', label: 'Fashion Designer' },
    { value: 'Financial-Analyst', label: 'Financial Analyst' },
    { value: 'Firefighter', label: 'Firefighter' },
    { value: 'Farmer', label: 'Farmer' },
    { value: 'Fisherperson', label: 'Fisherperson' },
    { value: 'Forensic-Scientist', label: 'Forensic Scientist' },
    { value: 'Geneticist', label: 'Geneticist' },
    { value: 'Geologist', label: 'Geologist' },
    { value: 'Graphic-Designer', label: 'Graphic Designer' },
    { value: 'Hairdresser', label: 'Hairdresser' },
    { value: 'Health-Educator', label: 'Health Educator' },
    { value: 'Herbalist', label: 'Herbalist' },
    { value: 'IT-Professional', label: 'IT Professional' },
    { value: 'Journalist', label: 'Journalist' },
    { value: 'Lawyer', label: 'Lawyer' },
    { value: 'Linguist', label: 'Linguist' },
    { value: 'Lecturer', label: 'Lecturer' },
    { value: 'Marketing-Specialist', label: 'Marketing Specialist' },
    { value: 'Mechanic', label: 'Mechanic' },
    { value: 'Mechanical-Engineer', label: 'Mechanical Engineer' },
    { value: 'Medical-Doctor', label: 'Medical Doctor' },
    { value: 'Meteorologist', label: 'Meteorologist' },
    { value: 'Military', label: 'Military' },
    { value: 'Miner', label: 'Miner' },
    { value: 'Mobile-App-Developer', label: 'Mobile App Developer' },
    { value: 'Nurse', label: 'Nurse' },
    { value: 'Occupational-Therapist', label: 'Occupational Therapist' },
    { value: 'Oil-and-Gas-Engineer', label: 'Oil and Gas Engineer' },
    { value: 'Paramedic', label: 'Paramedic' },
    { value: 'Pharmacist', label: 'Pharmacist' },
    { value: 'Phlebotomist', label: 'Phlebotomist' },
    { value: 'Photographer', label: 'Photographer' },
    { value: 'Physiotherapist', label: 'Physiotherapist' },
    { value: 'Pilot', label: 'Pilot' },
    { value: 'Policeman', label: 'Policeman' },
    { value: 'Policy-Maker', label: 'Policy Maker' },
    { value: 'Psychologist', label: 'Psychologist' },
    { value: 'Public-Relations-Specialist', label: 'Public Relations Specialist' },
    { value: 'Public-Sector-Worker', label: 'Public Sector Worker' },
    { value: 'Private-Sector-Worker', label: 'Private Sector Worker' },
    { value: 'Quality-Assurance-Analyst', label: 'Quality Assurance Analyst' },
    { value: 'Radiologist', label: 'Radiologist' },
    { value: 'Scientist', label: 'Scientist' },
    { value: 'Social-Media-Manager', label: 'Social Media Manager' },
    { value: 'Social-Worker', label: 'Social Worker' },
    { value: 'Software-Developer', label: 'Software Developer' },
    { value: 'Solar-Panel-Technician', label: 'Solar Panel Technician' },
    { value: 'Speech-Therapist', label: 'Speech Therapist' },
    { value: 'Student', label: 'Student' },
    { value: 'Surveyor', label: 'Surveyor' },
    { value: 'Teacher', label: 'Teacher' },
    { value: 'Textile-Designer', label: 'Textile Designer' },
    { value: 'Tour-Guide', label: 'Tour Guide' },
    { value: 'Tourism-Consultant', label: 'Tourism Consultant' },
    { value: 'Tourism-Manager', label: 'Tourism Manager' },
    { value: 'Traditional-Weaver', label: 'Traditional Weaver' },
    { value: 'Translator', label: 'Translator' },
    { value: 'UX-UI-Designer', label: 'UX/UI Designer' },
    { value: 'Veterinarian', label: 'Veterinarian' },
    { value: 'Waste-Management-Specialist', label: 'Waste Management Specialist' },
    { value: 'Web-Designer', label: 'Web Designer' },
    { value: 'Zoologist', label: 'Zoologist' },
  { value: 'Other', label: 'Other' }
];






function FormRegister() {
  // const [imageSrc, setImageSrc] = useState('');


  const [selectedOption, setSelectedOption] = useState(null);
  const [otherProfessions, setOtherProfessions] = useState('');

  const handleChange = (option) => {
      setSelectedOption(option);
  };
  const handleOtherChange = (event) => {
    setOtherProfessions(event.target.value);
};
  // const showAlert = () => {
  //     if (selectedOption) {
  //         alert(`You selected: ${selectedOption.label}`);
  //     } else {
  //         alert('Please select a category');
  //     }
  // };

  const showAlert = () => {
    if (selectedOption?.value) {
        if (selectedOption.value === 'Other') {
            alert(`You selected: Other - ${otherProfessions}`);
        } else {
            alert(`You selected: ${selectedOption.label}`);
        }
    } else {
        alert('Please select a profession');
    }
};






  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");

  
  const [MiddleName, setMiddleName] = useState("");
  const [Address, setAddress] = useState("");
  const [DateofBirth, setDateofBirth] = useState("");
  
  const [ID_Type_Number, setID_Type_Number] = useState("");
  const [Ministry, setMinistry] = useState("");
  const [DateofRegistration, setDateofRegistration] = useState("");
  const [SocialMedia, setSocialMedia] = useState("");
  const [BiometricDataType, setBiometricDataType] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  
  
  
  

  const [COPMember, setCOPMember] = useState(null);
  const [Select_id_type, setSelect_id_type] = useState("");
  const [district, setDistrict] = useState("");
  const [local, setLocal] = useState("");
  const [otherReligion, setOtherReligion] = useState("");
  const [course, setCourse] = useState("");
  const [base64Image, setBase64Image] = useState('');
   const [Member_Status, setMember_Status] = useState("");

  const [Profession, setProfession] = useState("");
  const [OtherProfession, setOtherProfession] = useState("");

  const [showOtherSelect_id_type, setShowOtherSelect_id_type] = useState(false);
  const [otherSelect_id_type, setOtherSelect_id_type] = useState("");
  
  const [copguild, setCopguild] = useState("");
  const [Studentschool, setStudentschool] = useState("");
  const [StudentCourse, setStudentCourse] = useState("");
  
  const [StudentLevel, setStudentLevel] = useState("");



  
  const [showOtherChurchGuild, setShowOtherChurchGuild] = useState(false);
  
  const [otherChurchGuild, setOtherChurchGuild] = useState("");

  const [OtherMemberStatus, setOtherMemberStatus] = useState("");

  


  const handleFileInputChange = (e) => {
    const selectedImage = e.target.files[0];
    
    if (selectedImage) {
      if (selectedImage.size > 400 * 1024) {
        // File size is larger than 400KB, clear the input
        e.target.value = '';
        setImage(null);
        // alert('File size should be no larger than 400KB');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
            iconColor: 'red',
          customClass: {
              popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 5500,
          timerProgressBar: true
      })
      Toast.fire({
          type: "error",
          icon: 'error',
          color: "red",
        //   background: 'red',
          title: 'File size should be no larger than 400KB ',
          // text: 'Something went wrong!', email and password must be filled out
      })
 
        return;
      }

      const imageURL = URL.createObjectURL(selectedImage);
      setImage(imageURL);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
    }
  };
 


  const getErrorMessage = () => {
    if (currentStep === 1) {
      if (!firstName || !/^[a-zA-Z0-9\s\-_:]+$/.test(firstName)) {
        return "Invalid first name.";
      }
    
      if (!lastName || !/^[a-zA-Z\s\-_:]+$/.test(lastName)) {
        return "Invalid Surname.";
      }

    // if (!email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/.test(email)) {
    //   return "Email validation error.";
    // }

    // if (!phoneNumber || !/^[\d\s\-]+$/.test(phoneNumber)) {
    //   return "Phone number validation error.";
    // }

    //!phoneNumber || !/^\d{10}$/.test(phoneNumber)
    //if (!phoneNumber || !/^\+?[\d\s\-]+$/.test(phoneNumber)) {
    if (!phoneNumber ||  !/^\d{10}$/.test(phoneNumber)) {
      return "Phone number validation error.";
    }
      
    if (!DateofBirth || !/^\d{4}-\d{2}-\d{2}$/.test(DateofBirth)) {
      return "Date of Birth validation error.";
  }
  
  

    if (!gender) {
        return "Gender is required.";
    }

      
    }
    // if (!email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/.test(email)) {
    //   return "Email validation error.";
    // }

    if (currentStep === 2) {
        

      if (!Address || !/^[a-zA-Z0-9\s\-_,.:]+$/.test(Address)) {
        return "Invalid Address.";
      }
    
      if (!district || !/^[a-zA-Z0-9\s\-_:]+$/.test(district)) {
        return "Invalid district.";
      }
    
      if (!local || !/^[a-zA-Z0-9\s\-_:]+$/.test(local)) {
        return "Invalid City/Town.";
      }

      if (!Select_id_type || !/^[a-zA-Z0-9\s\-_:]+$/.test(Select_id_type)) {
        return "Invalid id type.";
      }
    

      if (!ID_Type_Number || !/^[a-zA-Z0-9\s\-_,.:]+$/.test(ID_Type_Number)) {
        return "Invalid ID Type/Number.";
      }
       
      if (!ID_Type_Number || !/^[a-zA-Z0-9\s\-_,.:]+$/.test(ID_Type_Number)) {
        return "Invalid ID Type/Number.";
      }
       

      if (!Ministry || !/^[a-zA-Z0-9\s\-_,.:]+$/.test(Ministry)) {
        return "Invalid inputs School/Ministry/Agency/Dep.";
      }


          if (Select_id_type === "Other" && (!otherSelect_id_type)) 
          {
            return "Specify type.";
          }
       
      
    }

    if (currentStep === 3 && !DateofRegistration) 
    {
        return "Date of Registration is required in step 3.";
    }

    



    
 
 

    return null;  // No errors found
};

const isFormValid = () => {
    return getErrorMessage() === null;
};

const nextStep = () => {
    const errorMessage = getErrorMessage();
    if (errorMessage) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'red',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        });
        Toast.fire({
            type: "error",
            icon: 'error',
            title: errorMessage,
        });

        return;
    }
    setCurrentStep(currentStep + 1);
};
 


  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

 


  const handleToast = (icon, title, color) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: color,
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true
    });
  
    Toast.fire({ icon, title });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!phoneNumber || !firstName) {
      setLoading(false);
      handleToast('error', 'Form validation error!', 'red');
    } else {
      const data = {
        // step 1
        firstName,
        lastName,
        MiddleName,
        DateofBirth,
        phoneNumber,
        gender,

        // step 2
       Address,
       email,
       district,
       local,
       Select_id_type: showOtherSelect_id_type ? otherSelect_id_type : Select_id_type,
       ID_Type_Number,

      //  profession: Profession === 'Other' ? OtherProfession : Profession,
      // selectedOption
    profession: selectedOption.label === 'Other' ? otherProfessions : selectedOption.label,
 
       Ministry,

        // step 3
        SocialMedia,
        DateofRegistration,
        PaymentType,
        AccountNumber,
        BiometricDataType,
    
       
        base64Image 
      };
      api.post('/api/register', data, {
      // axios.post('http://localhost:8000/api/register', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'Bearer YOUR_TOKEN' // if needed
        }
      })
      .then(res => {
        if (res && res.data && res.data.message) {
          handleToast('success', res.data.message, 'green');
          setImage(/* Your dlogo or any other image */);
          setLoading(false);
          setTimeout(() => { window.location = "/thankyou"; }, 7000);
        } else if (res && res.response && res.response.data && res.response.data.response && res.response.data.response.message) {
          setLoading(false);
          handleToast('error', 'Error: ' + res.response.data.response.message, 'red');
        } else {
          setLoading(false);
          handleToast('error', 'Error: An unknown error occurred', 'red');
        }
      })
      .catch(err => {
        setLoading(false);
 
 
          setLoading(false);
        
          // First, fix the typo: change 'errponse' to 'response'
          if (err && err.response && err.response.data && err.response.data.message) {
            handleToast('error', 'Error: ' + err.response.data.message, 'red');
          } else {
            //  alert(err.response.data.response.message)
            
        const { status, data } = err.response;

        if(status===403){
          handleToast('error', 'This user account is already in use!', 'red');
           }
          else if (status === 409) {
            handleToast('error', 'This user account is already in use!', 'red');
          
           }

           else if (status === 400) {
            handleToast('error', 'Bad Request: Please check your input!', 'red');
          
           }

           else if (status === 404) {
            handleToast('error', 'Not Found: The requested resource was not found!', 'red');
          
           }

           else if (status === 500) {
            handleToast('error', 'Internal Server Error: Something went wrong on our end!', 'red');
          
           }

         else{
         handleToast('error', 'Error: Connection fail', 'red');
           }
         

          }
         
        // const errorMessage = err.response?.data?.message || 'An error occurred';
        // handleToast('error', 'Error: ' + errorMessage, 'red');
      });
    }
  }
  


  useEffect(() => {
    // Function to get the current date in dd/mm/yyyy format
    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Set the current date as the default value
    setDateofRegistration(getCurrentDate());
}, []);



  return (
    <> 

    {loading ? (
  <div class="containers"> 
    <div   class="loading"></div></div>

            ) : (<></>)}

      {/* ---------------Navigation------------ */}
      <div>
        <div  className='horizontal-nav'>
          <nav className="navbar navbar-expand-lg">
            <div id="nx-nav" className="container">
              <i class='bx bx-menu bx-lg'
               className="navbar-toggler" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" 
                aria-controls="navbarNavDropdown" aria-expanded="false"
                 aria-label="Toggle navigation">
            
                <i class='bx bx-menu bx-lg'></i>
              </i>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item active">
           
                    <a href="#">Home</a>
                  </li>
                  <li id='nav-li-id' >
                    <a href="####"><img src={pent} alt="logo" id='logo' /></a> 
                  </li>
                </ul>
 
              </div>
            </div>
          </nav>

          
        </div>
      </div>

      {/* ---------------Navigation------------ */}
      {/* ================================================================================== */}
    
      <div id="#signup-sec" className="container-fluid">


        <form onSubmit={handleSubmit} id="myform">
          {/* Step 1 */}
          {currentStep === 1 && (
            <>
              <div className="form-group">
                <h6 id="hd" >NTA Tap-n-Ride Registration Portal </h6>
                {/* =========================================================== */}
                {/* <h5>  {error && <p>{error}</p>}</h5> */}
                <div id='imgUpload'>
                    <div>
                      <div>
                      <img width="100" height="100" id="previewImg"
                             src={image || dlogo} alt='copitguild'
                         
                      class="img-responsive rounded-circle center-block d-block mx-auto"
                        onError={(e) => { e.target.onerror = null; e.target.src = dlogo }}
                       />
                                                        
          <input type="file" id="image" onChange={handleFileInputChange} />
                      </div>
                    </div>
                  </div>

                {/* =========================================================== */}
                <div class="row">
                <div class="col-md-6">
                <label>
                  First Name *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-user bx-sm '></i>
                    <input placeholder="Enter First Name" type="text" value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={!firstName ? "error" : ""}
                      class="form-control"
                      required />
                  </div>

                </label>
                </div>
 
                <div class="col-md-6">
                <label>
                Surname *
                  <div id="fn">
                    <i style={{marginTop:"16px"}}  class='bx bxs-user bx-sm '></i>
                    <input placeholder="Enter Surname" type="text" value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className={!lastName ? "error" : ""}
                      class="form-control" required />
                  </div>
                </label>
                </div>
                </div>
                {/* ================================================ */}




                <div class="row">


                <div class="col-md-6">
                <label>
                Middle Name:

                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-smile bx-sm' ></i>
                    <input placeholder="Enter Middle Name" type="text" value={MiddleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
                </div>




                <div class="col-md-6">
                <label>
                 Date of Birth :

                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-calendar bx-sm' ></i>
                    <input placeholder="Enter Date of Birth" type="date" value={DateofBirth}
                      onChange={(e) => setDateofBirth(e.target.value)}
                       required
                      class="form-control"  />
                  </div>
                </label>
                </div>

                </div>

                {/* =========================================================== */}
                <div class="row">

             


                <div class="col-md-6">
                <label>
                  Gender *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-male-female  bx-sm' ></i>
                     <select class="form-control"
                      value={gender} onChange={(e) => setGender(e.target.value)} 
                     className={!gender ? "error" : ""}   required>
                      <option value="">--Select Gender--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </label>
                </div>


                <div class="col-md-6">
                <label>
                Contact Number *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bxs-phone bx-sm'></i>
                    <input placeholder="Enter mobile phone Number"   value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className={!phoneNumber ? "error" : ""}
                      class="form-control"
                      
              type="number" 
                 pattern="[0-9]{10}"     
                       
                      required />
                  </div>
                </label>
                </div>



                </div>



              </div>

              <button type="button" className="btn btn-danger" onClick={nextStep}>Next</button>
            </>
          )}


          {/* Step 2 */}
          {currentStep === 2 && (
            <>
                <h6 id="hd" >NTA Tap-n-Ride Registration Portal</h6>
                 
              <h4>Step 2</h4>
             
              
                <div class="row">
     
                

                <div class="col-md-6">
<label>
                Address *
  <div id="fn">
    <i style={{marginTop:"16px"}}  class='bx bx-home bx-sm'></i>
   
    {/* <input  type="text" /> */}

    <textarea 
     value={Address} onChange={(e) => setAddress(e.target.value)}
    class="form-control" placeholder="Enter your physical Address"   rows="1" cols="50"></textarea>

  </div>
</label>
 </div>



 <div class="col-md-6">
                <label>
                  Email :

                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-envelope bx-sm' ></i>
                    <input placeholder="Enter Email" type="email" value={email}
                      onChange={(e) => setEmail(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
                </div>




 
 <div class="col-md-6">
                  <label>
                  District/County *
                    <div id="fn">
                      <i style={{marginTop:"16px"}}  class='bx bx-card bx-sm'></i>
                     
                    <input placeholder="Enter District/County" type="text" value={district} onChange={(e) => setDistrict(e.target.value)} />
                    </div>
                  </label>
</div>


<div class="col-md-6">
                  <label>
                   City / town *  
                    <div id="fn">
                      <i style={{marginTop:"16px"}}  class='bx bx-buildings bx-sm'></i>
                     
                    <input placeholder="Enter  City / town  " type="text" value={local} onChange={(e) => setLocal(e.target.value)} />
                    </div>
                  </label>
</div>
                
              
 


                <div class="col-md-6">
                <label>
  Select id type *
  <div id="fn"> 
    <i style={{marginTop:"16px"}}   class='bx bx-id-card  bx-sm'></i>
    <select class="form-control" value={Select_id_type} onChange={(e) => {
      setSelect_id_type(e.target.value);
      if (e.target.value === "Other") {
        setShowOtherSelect_id_type(true);
      } else {
        setShowOtherSelect_id_type(false);
        setOtherSelect_id_type(""); // Clear the input field value
      }
    } } >
        <option value="">--Select--</option>
              <option value="National-Identification-Card">National Identification Card (NID Card)</option>
                        <option value="Passport" >Passport</option>
                       
                        <option value="Driver-License">Driver's License</option>
                        <option value="Student-ID">Student ID</option> 

      <option value="Other">Other</option>
    </select>
  </div>
 
{showOtherSelect_id_type && (

  <>

    Enter Other :
    <div id="fn">
      <i style={{marginTop:"16px"}} class='bx bx-pencil bx-sm'></i>
      <input
        type="text"
        value={otherSelect_id_type}
        onChange={(e) => setOtherSelect_id_type(e.target.value)}
         
        class="form-control"
        placeholder="Other (specify)"
        required
      />
    </div>
    </>
)}
 </label>
</div>


<div class="col-md-6"> 
<label>
ID Type/Number


                  <div id="fn">  
                    <i style={{marginTop:"16px"}}class='bx  bx-credit-card bx-sm' ></i>
                    <input placeholder="Enter ID  Number" type="text" value={ID_Type_Number}
                      onChange={(e) => setID_Type_Number(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
  
</div>
 
               
                </div>



                <div class="row"> 
                   

                <div class="col-md-6">
                <div className="form-group  ">
                <label htmlFor="categorySelect">Choose your Occupation:
                <Select  class="form-control"
                    id="categorySelect"
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select a category"
                    isClearable
                />
                </label>
            </div>
              {selectedOption?.value === 'Other' && (
                <div className="form-group">
                    <label htmlFor="otherProfession">Specify your profession:</label>
                    <input
                        type="text"
                        className="form-control "
                        id="otherProfession"
                        value={otherProfessions}
                        onChange={handleOtherChange}
                        placeholder="Enter your profession"
                    />
                </div>
            )}

</div>


                <div class="col-md-6">
                <label>
                School/Ministry/Agency/Department:

                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-bank bx-sm' ></i>
                    <input placeholder="Enter School/Ministry/Agency/Dep." 
                    type="text" value={Ministry}
                      onChange={(e) => setMinistry(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
                </div>




                 </div>





            
              <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
              <button type="button" className="btn btn-danger" onClick={nextStep}>Next</button>
            </>
          )}


          {/* Step 3 */}
          {/* Step 3 */}
{currentStep === 3 && (
    <>
        <h6 id="hd">NTA Tap-n-Ride Registration Portal</h6>
        <br />
        <div className="form-group">
            <h6>Finish up </h6>



            <div class="row">


            <div class="col-md-6">
                <label>
                Social Media handles:
                 
                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxl-instagram bx-sm' ></i>
                    <input placeholder="Enter  Social Media handles" type="text" 
                    value={SocialMedia} 
                      onChange={(e) => setSocialMedia(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
                </div>

                <div class="col-md-6">
                <label>
                Date of Registration *:
                 
                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-calendar bx-sm' ></i>
                    <input placeholder="Enter Date of Registration" type="text" 
                    value={DateofRegistration}
                      onChange={(e) => setDateofRegistration(e.target.value)}
                       disable
                       readOnly
                      class="form-control"  
                       
                      />
                  </div>
                </label>
                </div>

<hr/>
                <h5><b><u>Payment Information</u></b></h5>

                <div class="col-md-6">
                <label>
                 Payment Type  
                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bx-credit-card-front  bx-sm' ></i>
                    <select class="form-select" 
                       value={PaymentType}
                       onChange={(e) => setPaymentType(e.target.value)}
                     
                    >
            <option selected>Choose...</option>
            {/* <option value="creditCard">Credit Card</option>
            <option value="debitCard">Debit Card</option>
            <option value="eWallet">E-Wallet</option> */}
            <option value="momo">MOMO</option>
  <option value="debitCard">DEBIT CARD</option>
  <option value="bankAccount">BANK ACCT</option>
  <option value="voucher">VOUCHER</option>


            </select> 
                  </div>
                </label>
                </div>



                <div class="col-md-6">
                <label>
                 
                Account Number
                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-credit-card-front bx-sm' ></i>
                    <input type="text" class="form-control" id="accountNumber" 
                       value={AccountNumber}
                       onChange={(e) => setAccountNumber(e.target.value)}
                      
                    placeholder="Enter Account Number" />
       
                  </div>
                </label>
                </div>
 
 
                <div class="col-md-6">
                <label>
                 
                Biometric Data Type
                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-credit-card-front bx-sm' ></i>
                    <select class="form-select"  value={BiometricDataType}
                       onChange={(e) => setBiometricDataType(e.target.value)}
                   
                   >
            <option selected>Choose...</option>
            <option value="fingerprint">Fingerprint</option>
            <option value="facialRecognition">Facial Recognition</option>
            <option value="irisScan">Iris Scan</option>
            </select>
       
                  </div>
                </label>
                </div>


             

              </div>

         



              {/* <button className="btn btn-primary hideall" onClick={showAlert}>Submit</button> */}
       




        <div className="form-group">
          
         {/* Applicant's Consent */}
    <div className="form-group">
      <label style={{ color: "red", marginLeft: "10px" }}>Applicant's Consent</label>
      <h6  htmlFor="terms">
      By completing and submitting this form, you grant your explicit consent to the Liberia National Transport Authority's use of your personal information solely for purposes related to this registration.
      </h6>
    </div>
        </div>

        <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
        <button type="submit" className="btn btn-success" disabled={!isFormValid()}>Submit</button>
   
        </div>

    </>
)}

        </form>
      </div>



    </>

  );
}

export default FormRegister;


